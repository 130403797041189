import React from "react"
import NavigationBar from "../components/NavigationBar"
import Jumbotron from "../components/Jumbotron"

const Layout = props => (
  <>
    <NavigationBar />
    <Jumbotron header={props.header} pageSummary={props.pageSummary} />
    {props.children}
  </>
)

export default Layout
